<template>
    <div>
<!--        <page-title :titie_name="$t('m.channel.qudaoxiangqing')"/>-->
        <el-card shadow="hover" class="card">
            <el-form :inline="true" size="small">
                <el-form-item :label="$t('m.channel.time')">
                    <el-date-picker v-model="FormData.Date"
                                    type="daterange"
                                    value-format="yyyy-MM-dd"
                                    unlink-panels
                                    :range-separator="$t('m.pubilc.zhi')"
                                    :start-placeholder="$t('m.channel.kaishishijian')"
                                    :end-placeholder="$t('m.channel.jieshushijiang')"
                                    :clearable="false"
                                    @change="optionChanged">

                    </el-date-picker>
                </el-form-item>
                <el-form-item :label="$t('m.channel.zhibiao')">
                    <el-select v-model="FormData.Option"
                               @change="optionChanged">
                        <el-option v-for="option in OptionList"
                                   :label="option.name"
                                   :key="option.id"
                                   :value="option.id"/>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('m.channel.qudao')">
                    <el-select v-model="FormData.Channel"
                               @change="optionChanged">
                        <el-option v-for="item in AllChannelList"
                                   :label="item.channel"
                                   :key="item.id"
                                   :value="item.id"/>
                    </el-select>
                </el-form-item>
            </el-form>
        </el-card>

        <el-card shadow="hover" class="card">
            <div id="charts"/>
        </el-card>

        <el-card shadow="hover" class="card">
            <el-table :data="Result.list" class="eltable">
                <el-table-column :label="$t('m.channel.qudao')"
                                 prop="channel"
                                 align="center">

                </el-table-column>
                <el-table-column align="center"
                                 prop="channelNewUser">
                    <template slot="header" slot-scope="scope">
                        {{$t("m.channel.benqudiaoxinyonghu")}}
                        <el-tooltip class="item"
                                    effect="dark"
                                    placement="right">
                            <div slot="content">{{$t("m.channel.diyicigaiqudaoyonghushu")}}，<br/>{{$t("m.channel.yonghukenengqidtaqudao")}}</div>
                            <i class="el-icon-warning"/>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center"
                                 prop="newUser">
                    <template slot="header" slot-scope="scope">
                        {{$t("m.channel.xinyonghu")}}
                        <el-tooltip class="item"
                                    effect="dark"
                                    placement="right">
                            <div slot="content">{{$t("m.channel.diyicigaiqudaoyonghushu")}}，<br/>{{$t("m.channel.bingqiezhiqiangweishiqitaxd")}}</div>
                            <i class="el-icon-warning"/>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center"
                                 prop="runCount">
                    <template slot="header" slot-scope="scope">
                        {{$t("m.channel.qidongcishu")}}
                        <el-tooltip class="item"
                                    effect="dark"
                                    placement="right">
                            <div slot="content">{{$t("m.channel.gaiqudaoqidingcishu")}}</div>
                            <i class="el-icon-warning"/>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center"
                                 prop="runUser">
                    <template slot="header" slot-scope="scope">
                        {{$t("m.channel.qidongyonghu")}}
                        <el-tooltip class="item"
                                    effect="dark"
                                    placement="right">
                            <div slot="content">{{$t("m.channel.gaiqudaoqidongyonghu")}}</div>
                            <i class="el-icon-warning"/>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-row type="flex" style="justify-content: flex-end">
                <el-pagination
                        background
                        :pageSize="pageSize"
                        :current-page.sync="pageNum"
                        @current-change="pageNumChanged"
                        layout="total,prev, pager, next"
                        :total="Result.total">
                </el-pagination>
            </el-row>
        </el-card>
    </div>
</template>

<script>
    import HighCharts from 'highcharts'
    import pageTitle from "components/manager/page_title.vue"
    import {findAllChannel,findChannelStatisticData,filterStatisticData} from "request/api/client_list"
    import {stringForDate} from "../../../utils/DateUtil"

    export default {
        components:{
            pageTitle,
            HighCharts
        },
        name: "ChannelList",
        data(){
            return {
                FormData:{
                    Channel:'',
                    Option:'runCount',
                    Date:[],
                },
                AllChannelList:[],
                OptionList:[
                    {
                    id:'runCount',
                    name: this.$t("m.channel.qidongcishu")
                },{
                    id:'channelNewUser',
                    name: this.$t("m.channel.benqudiaoxinyonghu")
                },{
                    id:'newUser',
                    name: this.$t("m.channel.xinyonghushu")
                },{
                    id:'runUser',
                    name: this.$t("m.channel.qidongyonghu")
                }],

                ChartOptions:{
                    title: { text: null },
                    yAxis: { title: { text: null} },
                    xAxis:{ type:'category'},
                    legend: {enabled:false},
                    credits:{enabled:false},
                    series: [{
                        name: this.$t("m.channel.shuliang"),
                        data: []
                    }]
                },
                pageSize:10,
                pageNum:1,
                Result:{}
            }
        },
        mounted() {

            // let now = new Date();
            // now.setMonth(now.getMonth() - 1);
            // this.FormData.Date = [stringForDate(now),stringForDate(new Date())];
            //
            // let id = this.$route.query.id;
            // if (id){
            //     this.FormData.Channel = id;
            //     this.optionChanged();
            // }
            //
            // this.getAllChannel();
            // this.getChannelData();
        },
        methods:{
            //获取所有的渠道
            getAllChannel(){
                let _this = this;
                findAllChannel().then(res=>{
                    _this.AllChannelList = res.data;
                    if (!_this.FormData.Channel){
                        _this.FormData.Channel = _this.AllChannelList[0].id;
                        _this.optionChanged();
                    }
                })
            },

            getChannelData(){
                let data = {
                    pageSize:this.pageSize,
                    pageNum:this.pageNum
                };
                let _this = this;
                findChannelStatisticData(data).then(res=>{
                    _this.Result = res.data;
                })
            },

            //筛选条件改变
            optionChanged(){

                let data = {
                    startTime:this.FormData.Date[0],
                    endTime:this.FormData.Date[1],
                    channelId:this.FormData.Channel
                };
                let _this = this;
                filterStatisticData(data).then(res=>{
                    let data = res.data;

                    this.ChartOptions.series[0].data = [];
                    data.forEach(d=>{
                        this.ChartOptions.series[0].data.push({y:d[this.FormData.Option],name:d.daytime});
                    });

                    HighCharts.chart('charts',this.ChartOptions)
                })
            },


            //切换页数
            pageNumChanged(page){
                this.pageNum = page;
                this.getChannelData();
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../../assets/style/index";

    .card{
        margin: 0 0 10px;
        border: none;
    }

    .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item{
        margin-bottom: 0;
    }

</style>
